import React from "react"
import { graphql } from "gatsby"
import SiteLayout from "../components/layout/index"
import Row from "../components/layout/row"
import styled from "@emotion/styled"
import { toLocalDate } from "../helpers"
import BackwardButton from "../components/common/backwardButton"
import usePostImages from "../hooks/use-post-images"
import useLastFourNews from "../hooks/use-last-four-news"
import NewsCard from "../components/common/news-card"
import Gallery from "../components/common/gallery"

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 31px;
  ${props => props.theme.tablet} {
    img {
      width: 100%;
      height: auto;
    }
  }
  img {
    height: auto;
  }
  .wp-block-gallery {
    display: none;
  }
`

const Content = styled.div`
  width: 70%;
  overflow: hidden;
  .alignleft {
    float: left;
    margin: 0 10px 5px 0;
    padding: 5px;
    ${props => props.theme.tablet} {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  .alignright {
    float: right;
    margin: 0 0 5px 10px;
    padding: 5px;
    ${props => props.theme.tablet} {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  .aligncenter {
    display: block;
    margin: 0 auto;
    height: auto;
    object-fit: cover;
    ${props => props.theme.tablet} {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  .alignnone {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  p {
    margin-bottom: 1rem;
  }
  ${props => props.theme.tablet} {
    width: 100%;
    p {
      text-align: justify;
    }
  }
`

const DateWrapper = styled.div`
  margin-bottom: 1rem;
`

const NewsCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 2rem -0.5rem;
  ${props => props.theme.tablet} {
    margin: 0;
  }
`

const Post = props => {
  const lastNews = useLastFourNews()
  const postImages = usePostImages()

  const {
    data: {
      wordPress: { post },
    },
  } = props
  const { title, content, date } = post

  return (
    <SiteLayout light>
      <Row margin="1rem auto">
        <ContentWrapper>
          <Content>
            <BackwardButton />
            <DateWrapper>{toLocalDate(date)}</DateWrapper>
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <Gallery content={content} width="33%" />
          </Content>
        </ContentWrapper>
        <NewsCardWrapper>
          {lastNews &&
            lastNews.map((post, idx) => (
              <NewsCard
                key={post.id}
                post={post}
                idx={idx}
                postImages={postImages}
                flexBasis="25%"
                height="auto"
              />
            ))}
        </NewsCardWrapper>
      </Row>
    </SiteLayout>
  )
}

export default Post

export const pageQuery = graphql`
  query GET_POST($id: ID!) {
    wordPress {
      post(id: $id) {
        title
        content
        uri
        date
      }
    }
  }
`
