import { graphql, useStaticQuery } from "gatsby"

const useLastFourNews = () => {
  const data = useStaticQuery(graphql`
    {
      wordPress {
        posts(first: 4) {
          nodes {
            id
            title
            uri
            slug
            excerpt
            date
            featuredImage {
              node {
                sourceUrl(size: LARGE)
              }
            }
          }
        }
      }
    }
  `)

  return data.wordPress.posts.nodes
}

export default useLastFourNews
